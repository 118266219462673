import * as Yup from 'yup';

import React, { useState, useEffect, useRef } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
// material
import {
  Card,
  CardContent,
  Box,
  Grid,
  Stack,
  Button,
  Container,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  TextField,
  NativeSelect
} from '@mui/material';
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
// components
import { connect } from 'react-redux';
import Page from '../../components/Page';

import { BreadCrumbs } from '../../components/_dashboard/user';
//

import { createLeave, getLeaves } from '../../redux/leave/leave.actions';
import { getRoles } from '../../redux/role/role.actions';

function LeaveForm(props) {
  const ref = useRef(null);

  const [reportingManager, setReportingManager] = useState([]);
  const userRole = JSON.parse(localStorage.getItem('role'));
  const userId = localStorage.getItem('referId');
  const breadcrumbs = [
    { title: 'Dashboard', path: '/dashboard/app' },
    { title: 'Leave', path: '/dashboard/user' },
    { title: 'New Leave', path: '/dashboard/usercreate' }
  ];
  const token = localStorage.getItem('token');
  const userManager = [];
  let userD;
  useEffect(() => {
    if (props.employees.length > 0) {
      /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < props.employees.length; i++) {
        // const role = props.employees[i].role;
        if (userRole.title === 'SUPER_ADMIN') {
          userD = JSON.parse(props.employees[i].role);
        } else {
          userD = props.employees[i].role;
        }
        console.log(userD, 'userD=====================');
        if (userD.title === 'PM' || userD.title === 'TL' || userD.title === 'ADMIN') {
          userManager.push(props.employees[i]);
          // setReportingManager(props.employees[i])
        }
      }
      setReportingManager(userManager);
    }
  }, [props.employees]);
  const [fileList, setFileList] = useState([]);
  const [degreeDoc, setDegreeDoc] = useState([]);
  const [salaryDoc, setSalaryDoc] = useState([]);
  const [experienceDoc, setExperienceDoc] = useState([]);
  const [governmentDoc, setGovernmentDoc] = useState([]);
  const [fromDate, setfromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [leaveType, setLeaveType] = useState('');

  const onChangeImage = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onChangeDegree = ({ fileList: degreeList }) => {
    setDegreeDoc(degreeList);
  };
  const onChangeGovernment = ({ fileList: governmentList }) => {
    setGovernmentDoc(governmentList);
  };
  const onChangeSalarySlip = ({ fileList: salaryList }) => {
    setSalaryDoc(salaryList);
  };
  const onChangeExperience = ({ fileList: experienceList }) => {
    setExperienceDoc(experienceList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
  }));

  const [age, setAge] = React.useState('');

  const handleChangeReporting = (event) => {
    console.log('hhhhhh', event.target.value);
    setAge(event.target.value);
  };
  const [subject, setSubject] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [inputList, setInputList] = useState([{ company_name: '', company_contact: '' }]);
  const [qualificationList, setQualificationList] = useState([
    { period: '', insititute: '', degree: '' }
  ]);
  const handleQualificationChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...qualificationList];
    list[index][name] = value;
    setQualificationList(list);
  };
  const UserSchema = Yup.object().shape({});
  const Input = styled('input')({
    display: 'none'
  });
  const formik = useFormik({
    initialValues: {
      leave_type: '',
      startDate: '',
      endDate: '',
      reason: '',
      custom_leave_type: ''
    },

    onSubmit: (state) => {
      const UserData = {
        leave_type: leaveType,
        startDate: fromDate,
        endDate: toDate,
        reason: state.leave_reason,
        custom_leave_type: state.custom_leave_type,
        // user: userRole,
        user: userId,
        reportingManager: localStorage.getItem('reportingManagerId')
      };
      props.createLeave(UserData);
    }
  });
  useEffect(() => {
    props.getRoles();
    // props.getLeaves();
  }, [getRoles]);
  const { errors, touched, handleSubmit, handleChange, isSubmitting, getFieldProps } = formik;

  const handleLeave = (e) => {
    if (e.target.value === 'Other') {
      setSubject(true);
    }
  };

  return (
    <Page title="User | Minimal-UI">
      <Container>
        <h2>Create a new leave</h2>
        <BreadCrumbs breadcrumbs={breadcrumbs} />

        <Box sx={{ flexGrow: 1 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Stack spacing={3}>
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Leave Type</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="leave_type"
                                    label="leave_type"
                                    name="leave_type"
                                    onChange={(e) => {
                                      setLeaveType(e.target.value);
                                      handleLeave(e);
                                    }}
                                  >
                                    <MenuItem value="Casual">Casual Leave</MenuItem>
                                    <MenuItem value="Medical">Medical Leave</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {subject && (
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Type Leave
                                    </InputLabel>
                                    <TextField
                                      fullWidth
                                      label="Type Leave"
                                      {...getFieldProps('custom_leave_type')}
                                      error={Boolean(
                                        touched.custom_leave_type && errors.custom_leave_type
                                      )}
                                      helperText={
                                        touched.custom_leave_type && errors.custom_leave_type
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                              )}
                              <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    label="from"
                                    value={fromDate}
                                    id="dob"
                                    name="dob"
                                    onChange={(newValue) => {
                                      setfromDate(newValue);
                                    }}
                                    maxDate={new Date()}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                  />
                                </LocalizationProvider>
                              </Grid>

                              <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    label="to"
                                    value={toDate}
                                    onChange={(newValue) => {
                                      setToDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Leave Reason
                                  </InputLabel>
                                  <TextField
                                    fullWidth
                                    label="Leave Reason"
                                    {...getFieldProps('leave_reason')}
                                    error={Boolean(touched.leave_reason && errors.leave_reason)}
                                    helperText={touched.leave_reason && errors.leave_reason}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <h3 className="mb-4">Document</h3>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={4}>
                            <h4>Upload Image</h4>
                            <Upload
                              action="http://localhost:8000/upload/uploadImage"
                              listType="picture-card"
                              fileList={fileList}
                              onChange={onChangeImage}
                              onPreview={onPreview}
                              multiple={false}
                              maxCount={1}
                              headers={{
                                Authorization: ` Bearer ${token}`
                              }}
                            >
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={12} className="margin-left">
                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                    <LoadingButton
                      size="large"
                      type="button"
                      variant="outlined"
                      loading={isSubmitting}
                    >
                      Cancel
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  user: state,
  userImage: state.employee.userImage,
  role: state.role.roles,
  employees: state.employee.employees
});

const mapDispatchToProps = (dispatch) => ({
  createLeave: (user) => dispatch(createLeave(user)),
  getRoles: (role) => dispatch(getRoles(role))
  // getLeaves: (user) => dispatch(getLeaves(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaveForm);
