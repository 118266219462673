import { filter } from 'lodash';

import { useState, useEffect, useRef } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Button,
  TablePagination
} from '@mui/material';
// components
import { connect } from 'react-redux';

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import USERLIST from '../../_mocks_/user';
import { getLeave, leaveAccept } from '../../redux/leave/leave.actions';

const TABLE_HEAD = [
  //   { id: 'type', label: 'Type', alignRight: false },
  { id: 'StartDate', label: 'StartDate', alignRight: false },
  { id: 'endData', label: 'EndData', alignRight: false },
  { id: 'reason', label: 'Reason', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function LeaveListing(props) {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [leave, setLeave] = useState([]);
  const userRole = JSON.parse(localStorage.getItem('role'));
  const k = window.location.href.split('/').length - 1;
  const id = window.location.href.split('/')[k];
  useEffect(() => {
    props.getLeave(id);
  }, [getLeave]);
  useEffect(() => {
    setLeave(props.leave);
  }, [props.leave]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  console.log(leave, 'leavses');
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;
  const handleAccept = (userId, value) => {
    props.leaveAccept({
      leaveId: userId,
      status: value
    });
  };
  return (
    <Page title="User | Minimal-UI">
      <Container>
        <div className="user_detail d-flex">
          <div className="d-grid">
            <div className="d-flex justify-content-start">
              <Typography variant="p" component="p">
                Name:
              </Typography>
              <Typography variant="subtitle2" component="subtitle2">
                {leave[0]?.userDetail?.first_name}&nbsp;{leave[0]?.userDetail?.last_name}
              </Typography>
            </div>
            <div className="d-flex justify-content-start">
              <Typography variant="p" component="p">
                Department:
              </Typography>
              <Typography variant="subtitle2" component="subtitle2">
                {leave[0]?.userDetail?.department}
              </Typography>
            </div>
            <div className="d-flex justify-content-start">
              <Typography variant="p" component="p">
                Reporting Manager:
              </Typography>
              <Typography variant="subtitle2" component="subtitle2">
                {leave[0]?.userDetail?.reporting_manager[0].name}
              </Typography>
            </div>
          </div>
        </div>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Leave's
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/usercreate"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={leave.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {props.leaveDetail?.map((item, index) => {
                    console.log('itemmmm', item);
                    return (
                      <TableRow key={index}>
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            onChange={(event) => handleClick(event, item?.leaveDetail.first_name)}
                          />
                        </TableCell> */}
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Typography variant="subtitle2" noWrap>
                              {item.type}
                            </Typography> */}
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {new Date(item.startDate).toDateString()}
                        </TableCell>
                        <TableCell align="left"> {new Date(item.EndDate).toDateString()}</TableCell>
                        <TableCell align="left">{item.reason}</TableCell>
                        <TableCell align="left">
                          {item.status === 'New' ? (
                            <>
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() => handleAccept(item._id, 'accept')}
                              >
                                Accept
                              </Button>

                              <Button
                                type="button"
                                variant="outlined"
                                className="ml-4"
                                onClick={() => handleAccept(item._id, 'reject')}
                              >
                                Reject{' '}
                              </Button>
                            </>
                          ) : (
                            <p>{item.status}</p>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={leave.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
const mapDispatchToProps = (dispatch) => ({
  getLeave: (leave) => dispatch(getLeave(leave)),
  leaveAccept: (user) => dispatch(leaveAccept(user))
});
const mapStateToProps = (state) => ({
  leave: state.leave.leaves,
  leaveDetail: state.leave.leaves[0].leaveDetail
});
export default connect(mapStateToProps, mapDispatchToProps)(LeaveListing);
