import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Avatar,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  CardContent
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from '../components/Page';
import { BreadCrumbs } from '../components/_dashboard/user';
import { getEmployee } from '../redux/employee/employee.actions';

function UserView(props) {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: 'Dashboard', path: '/dashboard/app' },
    { title: 'User', path: '/dashboard/user' },
    { title: 'User Detail', path: '/dashboard/usercreate' }
  ];
  const k = window.location.href.split('/').length - 1;
  const id = window.location.href.split('/')[k];

  useEffect(() => {
    props.getEmployee(id);
  }, [getEmployee]);

  return (
    <Page title="User | Techstriker-Portal">
      <Container>
        <h2>User</h2>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
        <Box sx={{ flexGrow: 1 }}>
          <Paper elevation={3} className="background-image">
            <Typography
              className="edit_icon"
              onClick={() => navigate(`/dashboard/useredit/${id}`, { replace: true })}
            >
              Edit
            </Typography>
            <div className="css-before">
              <div className="css-avtar">
                <Avatar
                  alt="Cindy Baker"
                  src="https://res.cloudinary.com/minimal-ui/image/upload/v1614655910/upload_minimal/avatar/minimal_avatar.jpg"
                  className="avtar-outer"
                />

                <div className="user_name">
                  <Typography variant="h4" component="h4">
                    {props.user?.first_name}&nbsp;{props.user?.last_name}
                  </Typography>
                  <Typography variant="p" component="p">
                    {props.user ? JSON.parse(props.user?.role).title : null}
                  </Typography>
                </div>
              </div>
              <span className="MuiBox-root css-o2ekhx">
                <span className="wrapper lazy-load-image-background blur lazy-load-image-loaded">
                  <img
                    className="MuiBox-root css-6jrdpz"
                    alt="profile cover"
                    src="https://minimal-assets-api.vercel.app/assets/images/covers/cover_2.jpg"
                  />
                </span>
              </span>
            </div>
            <div className="css-tsniyt">
              <div className="MuiTabs-root css-pc1mzd">
                <div className="MuiTabs-scrollableX MuiTabs-hideScrollbar css-oqr85h">&nbsp;</div>
                <div className="MuiTabs-scroller MuiTabs-hideScrollbar MuiTabs-scrollableX css-12qnib">
                  &nbsp;
                </div>
              </div>
            </div>
          </Paper>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Paper elevation={3} className="profile_card">
                <CardContent>
                  <Typography variant="h5" component="h5">
                    Personal Detail
                  </Typography>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Name
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.first_name}&nbsp;{props.user?.last_name}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Personal Email
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.email}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Official Email
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.official_email}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Joining Date
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.joining_date}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Department
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.department}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Designation
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user ? JSON.parse(props.user?.role).title : null}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Reporting Manager
                    </Typography>
                    {props.user?.reporting_manager?.map((item) => (
                      <Typography variant="subtitle2" component="subtitle2">
                        {item.name}
                      </Typography>
                    ))}
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      DOB
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.dob}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Mobile Number
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.mobileNumber}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Address
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.address} &nbsp;
                      {props.user?.city}&nbsp;
                      {props.user?.state}&nbsp;
                      {props.user?.country}&nbsp;
                      {props.user?.postalCode}
                    </Typography>
                  </div>
                </CardContent>
              </Paper>
            </Grid>

            {/* Official Detail */}

            <Grid item xs={4}>
              <Paper elevation={3} className="profile_card">
                <CardContent>
                  <Typography variant="h5" component="h5">
                    Official Detail
                  </Typography>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Experience
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.experience}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Type
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.experience_type}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      CTC
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {/* {props.user?.ctc}K */}****
                    </Typography>
                  </div>
                </CardContent>
              </Paper>
            </Grid>

            {/* Parent's Detail */}
            <Grid item xs={4}>
              <Paper elevation={3} className="profile_card">
                <CardContent>
                  <Typography variant="h5" component="h5">
                    Parent's Detail
                  </Typography>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Father Name
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.father_name}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Mother Name
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.mother_name}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Father Contact Number
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.father_contact_number}
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Emergency Contact Number
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      {props.user?.emergency_contact}
                    </Typography>
                  </div>
                </CardContent>
              </Paper>
            </Grid>

            {/*  Qualification Detail */}
            <Grid item xs={4}>
              <Paper elevation={3} className="profile_card">
                <CardContent>
                  <Typography variant="h5" component="h5">
                    Qualification Detail
                  </Typography>
                  <div className="d-flex">
                    <List>
                      {props.user?.qualification.map((item) => (
                        <ListItem>
                          <ListItemText primary={item.degree} />
                          &nbsp; <ListItemText primary={item.period} />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </CardContent>
              </Paper>
            </Grid>

            {/*  Company Detail */}
            <Grid item xs={4}>
              <Paper elevation={3} className="profile_card">
                <CardContent>
                  <Typography variant="h5" component="h5">
                    Company Detail
                  </Typography>
                  {props.user?.lastCompanyDetail.map((item) => (
                    <div className="d-flex">
                      <Typography variant="p" component="p">
                        {item.company_name}
                      </Typography>
                      <Typography variant="subtitle2" component="subtitle2">
                        {item.company_contact}
                      </Typography>
                    </div>
                  ))}
                </CardContent>
              </Paper>
            </Grid>

            {/*  Company Detail */}
            <Grid item xs={4}>
              <Paper elevation={3} className="profile_card">
                <CardContent>
                  <Typography variant="h5" component="h5">
                    Document
                  </Typography>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Salary Slip
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      <button>
                        <a href={props.user?.salarySlip} download>
                          &nbsp;Downlaod
                        </a>
                      </button>
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Experience Letter
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      <button>
                        <a href={props.user?.experienceDoc} download>
                          &nbsp; Downlaod
                        </a>
                      </button>
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Degree
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      <button>
                        <a href={props.user?.degreeDoc} download>
                          &nbsp; Downlaod
                        </a>
                      </button>
                    </Typography>
                  </div>
                  <div className="d-flex">
                    <Typography variant="p" component="p">
                      Government Id
                    </Typography>
                    <Typography variant="subtitle2" component="subtitle2">
                      <button>
                        <a href={props.user?.governmentId} download>
                          &nbsp; Downlaod
                        </a>
                      </button>
                    </Typography>
                  </div>
                </CardContent>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
const mapStateToProps = (state) => ({
  user: state.employee.getEmployee[0]
});

const mapDispatchToProps = (dispatch) => ({
  getEmployee: (user) => dispatch(getEmployee(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserView);
