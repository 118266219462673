import * as Yup from 'yup';

import React, { useState, useEffect, useRef } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
// material
import {
  Card,
  CardContent,
  Box,
  Grid,
  Stack,
  Button,
  Container,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  TextField,
  NativeSelect
} from '@mui/material';
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
// components
import { connect } from 'react-redux';
import Page from '../components/Page';

import { BreadCrumbs } from '../components/_dashboard/user';
//

import { createEmployee, uploadImage, getEmployees } from '../redux/employee/employee.actions';
import { getRoles } from '../redux/role/role.actions';

function CreateUser(props) {
  const ref = useRef(null);

  const [reportingManager, setReportingManager] = useState([]);
  const userRole = JSON.parse(localStorage.getItem('role'));
  const [reportingManagerDetail, setReportingManagerDetail] = useState([]);
  const breadcrumbs = [
    { title: 'Dashboard', path: '/dashboard/app' },
    { title: 'User', path: '/dashboard/user' },
    { title: 'New user', path: '/dashboard/usercreate' }
  ];
  const departments = [
    { label: 'SEO', value: 'SEO' },
    { label: 'SALES', value: 'SALES' },
    { label: 'UI/UX', value: 'UI/UX' },
    { label: 'DEVLEPOER', value: 'DEVLEPOER' },
    { label: 'ADMIN', value: 'ADMIN' }
  ];
  const token = localStorage.getItem('token');
  const userManager = [];
  let userD;
  useEffect(() => {
    if (props.employees.length > 0) {
      /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < props.employees.length; i++) {
        if (userRole.title === 'SUPER_ADMIN') {
          userD = JSON.parse(props.employees[i].role);
        } else {
          userD = props.employees[i].role;
        }
        // userD = JSON.parse(props.employees[i].role);
        console.log(userD, 'userD=====================');
        if (userD.title === 'PM' || userD.title === 'TL' || userD.title === 'ADMIN') {
          userManager.push(props.employees[i]);
          // setReportingManager(props.employees[i])
        }
      }
      setReportingManager(userManager);
    }
  }, [props.employees]);
  const [fileList, setFileList] = useState([]);
  const [degreeDoc, setDegreeDoc] = useState([]);
  const [salaryDoc, setSalaryDoc] = useState([]);
  const [experienceDoc, setExperienceDoc] = useState([]);
  const [governmentDoc, setGovernmentDoc] = useState([]);
  const [dateofbirth, setDateofbirth] = useState(new Date());
  const [joiningDate, setJoiningDate] = useState(new Date());
  const onChangeImage = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onChangeDegree = ({ fileList: degreeList }) => {
    setDegreeDoc(degreeList);
  };
  const onChangeGovernment = ({ fileList: governmentList }) => {
    setGovernmentDoc(governmentList);
  };
  const onChangeSalarySlip = ({ fileList: salaryList }) => {
    setSalaryDoc(salaryList);
  };
  const onChangeExperience = ({ fileList: experienceList }) => {
    setExperienceDoc(experienceList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
  }));

  const [age, setAge] = React.useState('');

  const handleChangeReporting = (event) => {
    console.log('hhhhhh', event.target.value);
    setAge(event.target.value);
  };

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [inputList, setInputList] = useState([{ company_name: '', company_contact: '' }]);
  const [qualificationList, setQualificationList] = useState([
    { period: '', insititute: '', degree: '' }
  ]);
  const handleQualificationChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...qualificationList];
    list[index][name] = value;
    setQualificationList(list);
  };
  const UserSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    last_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Last name required'),
    father_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Father name required'),
    mother_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Mother name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    official_email: Yup.string()
      .email('Email must be a valid email address')
      .required('Official email is required'),
    department: Yup.string().required('department is required')
  });
  const Input = styled('input')({
    display: 'none'
  });
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',

      mobileNumber: '',
      gender: '',
      qualification: '',
      father_name: '',
      mother_name: '',
      emergency_contact: '',
      department: '',
      experience: '',
      experience_type: '',
      address: '',
      city: '',
      country: '',
      postalCode: '',
      ctc: '',
      employee_type: '',
      lastCompanyDetail: '',
      userImage: '',
      dob: '',
      role: '',
      user: '',
      joining_date: '',
      official_email: '',
      salarySlip: '',
      experienceDoc: '',
      degreeDoc: '',
      governmentId: '',
      reporting_manager: []
    },
    validationSchema: UserSchema,
    onSubmit: (state) => {
      const images = [];
      const salarySlip = [];
      const governmentD = [];
      const experienceLetter = [];
      const degree = [];
      /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < fileList.length; i++) {
        images.push(fileList[i].response.url);
      }
      /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < salaryDoc.length; i++) {
        salarySlip.push(salaryDoc[i].response.url);
      }
      /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < degreeDoc.length; i++) {
        degree.push(degreeDoc[i].response.url);
      }
      /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < experienceDoc.length; i++) {
        experienceLetter.push(experienceDoc[i].response.url);
      }
      /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < governmentDoc.length; i++) {
        governmentD.push(governmentDoc[i].response.url);
      }

      const UserData = {
        first_name: state.first_name,
        last_name: state.last_name,
        email: state.email,
        mobileNumber: state.mobileNumber,
        gender: state.gender,
        qualification: qualificationList,
        father_name: state.father_name,
        mother_name: state.mother_name,
        emergency_contact: state.emergency_contact,
        department: state.department,
        experience: state.experience,
        experience_type: state.experience_type,
        address: state.address,
        city: state.city,
        country: state.country,
        postalCode: state.postal_code,
        ctc: state.ctc,
        employee_type: state.employee_type,
        lastCompanyDetail: inputList,
        userImage: '' || (images[0] !== null && images[0] !== undefined) ? images[0] : '',
        dob: dateofbirth,
        role: state.role,
        user: userRole,
        joining_date: joiningDate,
        official_email: state.official_email,
        reporting_manager: [
          {
            name: state.reporting_manager.first_name,
            email: state.reporting_manager.official_email,
            referId: state.reporting_manager.referId
          }
        ],
        salarySlip:
          '' || (salarySlip[0] !== null && salarySlip[0] !== undefined) ? salarySlip[0] : '',
        experienceDoc:
          '' || (experienceLetter[0] !== null && experienceLetter[0] !== undefined)
            ? experienceLetter[0]
            : '',
        degreeDoc: '' || (degree[0] !== null && degree[0] !== undefined) ? degree[0] : '',
        governmentId:
          '' || (governmentD[0] !== null && governmentD[0] !== undefined) ? governmentD[0] : ''
      };
      console.log(UserData, 'userData');
      props.createEmployee(UserData);
    }
  });
  useEffect(() => {
    props.getRoles();
    props.getEmployees();
  }, [getRoles, getEmployees]);
  const { errors, touched, handleSubmit, handleChange, isSubmitting, getFieldProps } = formik;

  const showResults = () => {
    window.alert('success');
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleAddQualificationClick = () => {
    setQualificationList([...qualificationList, { period: '', insititute: '', degree: '' }]);
  };
  const handleRemoveQualificationClick = (index) => {
    const qList = [...qualificationList];
    qList.splice(index, 1);
    setQualificationList(qList);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { company_name: '', company_contact: '' }]);
  };

  const handleReportingManager = (value) => {
    console.log('jjjjjjj');
    console.log(value, 'value=====');
  };
  console.log(props.user, 'user================');
  return (
    <Page title="User | Techstriker-Portal">
      <Container>
        <h2>Create a new user</h2>
        <BreadCrumbs breadcrumbs={breadcrumbs} />

        <Box sx={{ flexGrow: 1 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Stack spacing={3}>
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="First name"
                                  {...getFieldProps('first_name')}
                                  error={Boolean(touched.first_name && errors.first_name)}
                                  helperText={touched.first_name && errors.first_name}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Last name"
                                  {...getFieldProps('last_name')}
                                  error={Boolean(touched.last_name && errors.last_name)}
                                  helperText={touched.last_name && errors.last_name}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Personal Email"
                                  {...getFieldProps('email')}
                                  error={Boolean(touched.email && errors.email)}
                                  helperText={touched.email && errors.email}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Official Email"
                                  {...getFieldProps('official_email')}
                                  error={Boolean(touched.official_email && errors.official_email)}
                                  helperText={touched.official_email && errors.official_email}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    label="DOB"
                                    value={dateofbirth}
                                    id="dob"
                                    name="dob"
                                    onChange={(newValue) => {
                                      setDateofbirth(newValue);
                                    }}
                                    maxDate={new Date()}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                  />
                                </LocalizationProvider>
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Mobile Number"
                                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                  {...getFieldProps('mobileNumber')}
                                  error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                                  helperText={touched.mobileNumber && errors.mobileNumber}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="gender"
                                    // value={age}
                                    label="Gender"
                                    name="gender"
                                    onChange={handleChange}
                                  >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    label="Joining Date"
                                    value={joiningDate}
                                    onChange={(newValue) => {
                                      setJoiningDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  {/* <InputLabel id="demo-simple-select-label">Department</InputLabel> */}
                                  {/* <Select
                                    labelId="demo-simple-select-label"
                                    id="department"
                                    // value={age}
                                    name="department"
                                    label="Department"
                                    onChange={handleChange}
                                  >
                                    <MenuItem value="SEO">SEO</MenuItem>
                                    <MenuItem value="Sales">Sales</MenuItem>
                                    <MenuItem value="UI/UX">UI/UX</MenuItem>
                                    <MenuItem value="Developer">Developer</MenuItem>
                                    <MenuItem value="Admin">Admin</MenuItem>
                                  </Select> */}
                                  <TextField
                                    select
                                    fullWidth
                                    label="Department"
                                    placeholder="department"
                                    {...getFieldProps('department')}
                                    SelectProps={{ native: true }}
                                    error={Boolean(touched.department && errors.department)}
                                    helperText={touched.department && errors.department}
                                  >
                                    <option value="" />
                                    {departments.map((option) => (
                                      <option key={option.value} value={option.label}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </TextField>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                {/* <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Designation</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="role"
                                    name="role"
                                    label="role"
                                    onChange={handleChange}
                                  >
                                    {props.role.map((item, index) => (
                                      <>
                                        {item.isdeleted === false &&
                                        userRole.role.title !== item.title ? (
                                          <React.Fragment key={item.title}>
                                            <MenuItem value={item.title} key={index}>
                                              {item.title}
                                            </MenuItem>
                                          </React.Fragment>
                                        ) : null}
                                      </>
                                    ))}
                                  </Select>
                                </FormControl> */}

                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Designation</InputLabel>
                                  <Select
                                    aria-invalid="false"
                                    id="role"
                                    name="role"
                                    labelId="demo-simple-select-label"
                                    label="Reporting Manager"
                                    onChange={handleChange}
                                  >
                                    {props.role.map((item, index) =>
                                      userRole.role.title !== item.title ? (
                                        <MenuItem value={JSON.stringify(item)}>
                                          {item.title}
                                        </MenuItem>
                                      ) : null
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Reporting Manager
                                  </InputLabel>
                                  <Select
                                    aria-invalid="false"
                                    id="reporting_manager"
                                    name="reporting_manager"
                                    labelId="demo-simple-select-label"
                                    label="Reporting Manager"
                                    onChange={handleChange}
                                  >
                                    {reportingManager.map((item, index) =>
                                      item ? (
                                        <MenuItem value={item}>
                                          {item.first_name}
                                          {item.last_name}
                                          /({JSON.parse(item.role).title})
                                        </MenuItem>
                                      ) : (
                                        <MenuItem>No user found</MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <h3 className="mb-4">Official Detail</h3>
                        <FormikProvider value={formik}>
                          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <Grid
                                  container
                                  rowSpacing={1}
                                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                  <Grid item xs={4}>
                                    <TextField
                                      fullWidth
                                      label="Total Experience"
                                      {...getFieldProps('experience')}
                                      error={Boolean(touched.experience && errors.experience)}
                                      helperText={touched.experience && errors.experience}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <FormControl fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Experience Type
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="experience_type"
                                        // value={age}
                                        name="experience_type"
                                        label="Experience Type"
                                        onChange={handleChange}
                                      >
                                        <MenuItem value="Fresher">Fresher</MenuItem>
                                        <MenuItem value="Experience">Experience</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      fullWidth
                                      label="CTC"
                                      {...getFieldProps('ctc')}
                                      error={Boolean(touched.ctc && errors.ctc)}
                                      helperText={touched.ctc && errors.ctc}
                                    />
                                  </Grid>
                                </Grid>
                              </Stack>
                            </Stack>
                          </Form>
                        </FormikProvider>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <h3 className="mb-4">Parent's Detail</h3>
                        <Stack spacing={3}>
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Father name"
                                  {...getFieldProps('father_name')}
                                  error={Boolean(touched.father_name && errors.father_name)}
                                  helperText={touched.father_name && errors.father_name}
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Mother name"
                                  {...getFieldProps('mother_name')}
                                  error={Boolean(touched.mother_name && errors.mother_name)}
                                  helperText={touched.mother_name && errors.mother_name}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Father contact number"
                                  {...getFieldProps('father_number')}
                                  error={Boolean(touched.father_number && errors.father_number)}
                                  helperText={touched.father_number && errors.father_number}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Emergency contact number"
                                  {...getFieldProps('emergency_contact')}
                                  error={Boolean(
                                    touched.emergency_contact && errors.emergency_contact
                                  )}
                                  helperText={touched.emergency_contact && errors.emergency_contact}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <h3 className="mb-4">Address Detail</h3>

                        <Stack spacing={3}>
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={4}>
                                <TextField
                                  fullWidth
                                  label="Address"
                                  {...getFieldProps('address')}
                                  error={Boolean(touched.address && errors.address)}
                                  helperText={touched.address && errors.address}
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <TextField
                                  fullWidth
                                  label="City"
                                  {...getFieldProps('city')}
                                  error={Boolean(touched.city && errors.city)}
                                  helperText={touched.city && errors.city}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  fullWidth
                                  label="State"
                                  {...getFieldProps('state')}
                                  error={Boolean(touched.state && errors.state)}
                                  helperText={touched.state && errors.state}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  fullWidth
                                  label="Country"
                                  {...getFieldProps('country')}
                                  error={Boolean(touched.country && errors.country)}
                                  helperText={touched.country && errors.country}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  fullWidth
                                  label="Postal Code"
                                  {...getFieldProps('postal_code')}
                                  error={Boolean(touched.postal_code && errors.postal_code)}
                                  helperText={touched.postal_code && errors.postal_code}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <h3 className="mb-4">Official Detail</h3>
                        {qualificationList.map((x, i) => (
                          <>
                            <div className="d-flex">
                              <TextField
                                fullWidth
                                label="Period of Degree"
                                {...getFieldProps('period')}
                                error={Boolean(touched.period && errors.period)}
                                helperText={touched.period && errors.period}
                                onChange={(e) => handleQualificationChange(e, i)}
                              />

                              <TextField
                                fullWidth
                                label="Institution"
                                {...getFieldProps('institution')}
                                error={Boolean(touched.institution && errors.institution)}
                                helperText={touched.institution && errors.institution}
                                onChange={(e) => handleQualificationChange(e, i)}
                              />

                              <TextField
                                fullWidth
                                label="Degree"
                                {...getFieldProps('degree')}
                                error={Boolean(touched.degree && errors.degree)}
                                helperText={touched.degree && errors.degree}
                                onChange={(e) => handleQualificationChange(e, i)}
                              />

                              <div className="col-lg-3 form-group">
                                <div className="btn-box">
                                  {qualificationList.length !== 1 && (
                                    <Button
                                      variant="-"
                                      onClick={() => handleRemoveQualificationClick()}
                                    >
                                      -
                                    </Button>
                                  )}
                                  {qualificationList.length - 1 === i && (
                                    <Button
                                      variant="+"
                                      onClick={() => handleAddQualificationClick()}
                                    >
                                      +
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <h3 className="mb-4">Company Detail</h3>
                        {inputList.map((x, i) => (
                          <>
                            <div className="d-flex">
                              <TextField
                                fullWidth
                                label="Name"
                                {...getFieldProps('company_name')}
                                error={Boolean(touched.company_name && errors.company_name)}
                                helperText={touched.company_name && errors.company_name}
                                onChange={(e) => handleInputChange(e, i)}
                              />

                              <TextField
                                fullWidth
                                label="Contact"
                                {...getFieldProps('company_contact')}
                                error={Boolean(touched.company_contact && errors.company_contact)}
                                helperText={touched.company_contact && errors.company_contact}
                                onChange={(e) => handleInputChange(e, i)}
                              />

                              <div className="col-lg-3 form-group">
                                <div className="btn-box">
                                  {inputList.length !== 1 && (
                                    <Button variant="-" onClick={() => handleRemoveClick()}>
                                      -
                                    </Button>
                                  )}
                                  {inputList.length - 1 === i && (
                                    <Button variant="+" onClick={() => handleAddClick()}>
                                      +
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <h3 className="mb-4">Document</h3>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={4}>
                            <h4>Upload Image</h4>
                            <Upload
                              action="https://apihrmanagementportal.techstriker.com/upload/uploadImage"
                              listType="picture-card"
                              fileList={fileList}
                              onChange={onChangeImage}
                              onPreview={onPreview}
                              multiple={false}
                              maxCount={1}
                              headers={{
                                Authorization: ` Bearer ${token}`
                              }}
                            >
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          </Grid>
                          <Grid item xs={4}>
                            <h4>Salary Slip</h4>
                            <Upload
                              action="https://apihrmanagementportal.techstriker.com/upload/uploadImage"
                              listType="picture-card"
                              fileList={salaryDoc}
                              onChange={onChangeSalarySlip}
                              onPreview={onPreview}
                              multiple={false}
                              maxCount={1}
                              headers={{
                                Authorization: ` Bearer ${token}`
                              }}
                            >
                              <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                          </Grid>
                          <Grid item xs={4}>
                            <h4 className="text-normal text-gray-900 mb-8">
                              Upload Experience Letter
                            </h4>
                            <div className="flex gap-10 flex-wrap">
                              <Upload
                                action="https://apihrmanagementportal.techstriker.com/upload/uploadImage"
                                listType="picture-card"
                                fileList={experienceDoc}
                                onChange={onChangeExperience}
                                onPreview={onPreview}
                                multiple={false}
                                maxCount={1}
                                headers={{
                                  Authorization: ` Bearer ${token}`
                                }}
                              >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                              </Upload>
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <h4 className="text-normal text-gray-900 mb-8">Upload Degree</h4>
                            <div className="flex gap-10 flex-wrap">
                              <Upload
                                action="https://apihrmanagementportal.techstriker.com/upload/uploadImage"
                                listType="picture-card"
                                fileList={degreeDoc}
                                onChange={onChangeDegree}
                                onPreview={onPreview}
                                multiple={false}
                                maxCount={1}
                                headers={{
                                  Authorization: ` Bearer ${token}`
                                }}
                              >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                              </Upload>
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <h4 className="text-normal text-gray-900 mb-8">Upload Government Id</h4>
                            <div className="flex gap-10 flex-wrap">
                              <Upload
                                action="https://apihrmanagementportal.techstriker.com/upload/uploadImage"
                                listType="picture-card"
                                fileList={governmentDoc}
                                onChange={onChangeGovernment}
                                onPreview={onPreview}
                                multiple={false}
                                maxCount={1}
                                headers={{
                                  Authorization: ` Bearer ${token}`
                                }}
                              >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                              </Upload>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid xs={12} className="margin-left">
                    <LoadingButton size="large" type="submit" variant="contained">
                      Submit
                    </LoadingButton>
                    <LoadingButton size="large" type="button" variant="outlined">
                      Cancel
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  user: state,
  userImage: state.employee.userImage,
  role: state.role.roles,
  employees: state.employee.employees
});

const mapDispatchToProps = (dispatch) => ({
  createEmployee: (user) => dispatch(createEmployee(user)),
  uploadImage: (image) => dispatch(uploadImage(image)),
  getRoles: (role) => dispatch(getRoles(role)),
  getEmployees: (user) => dispatch(getEmployees(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
