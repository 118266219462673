import { EmployeeActionTypes } from './employee.types';

const INITIAL_STATE = {
  employees: [],
  currentEmployee: null,
  userImage: null,
  allemployees: [],
  getEmployee: []
};

const employeeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EmployeeActionTypes.GET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
        allemployees: action.payload
      };
    case EmployeeActionTypes.GET_EMPLOYEES_FILTER:
      return {
        ...state,
        employees: action.payload
      };
    case EmployeeActionTypes.GET_EMPLOYEE:
      return { employees: action.payload, getEmployee: action.payload };
    case EmployeeActionTypes.UPDATE_EMPLOYEE:
      return {
        employees: state.employees.map((test) =>
          test._id === action.payload._id ? action.payload : test
        )
      };
    case EmployeeActionTypes.CREATE_EMPLOYEE:
      return { employees: [...state.employees, action.payload] };
    case EmployeeActionTypes.DELETE_EMPLOYEE:
      return {
        employees: state.employees.filter((emp) => emp._id !== action.payload)
      };
    case EmployeeActionTypes.UPLOAD_IMAGE:
      return {
        userImage: action.payload
      };

    default:
      return state;
  }
};

export default employeeReducer;
