import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import {
  Eventcalendar,
  snackbar,
  setOptions,
  Popup,
  Button,
  Input,
  Textarea,
  Switch,
  Datepicker,
  SegmentedGroup,
  SegmentedItem
} from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';

import { connect } from 'react-redux';
import {
  getHolidays,
  createHoliday,
  updateHoliday,
  deleteHoliday
} from '../redux/holidays/holiday.actions';

setOptions({
  theme: 'ios',
  themeVariant: 'light'
});

const now = new Date();

const viewSettings = {
  calendar: { labels: true }
};
const responsivePopup = {
  medium: {
    display: 'anchored',
    width: 400,
    fullScreen: false,
    touchUi: false
  }
};
const colorPopup = {
  medium: {
    display: 'anchored',
    touchUi: false,
    buttons: []
  }
};
const colors = [
  '#ffeb3c',
  '#ff9900',
  '#f44437',
  '#ea1e63',
  '#9c26b0',
  '#3f51b5',
  '',
  '#009788',
  '#4baf4f',
  '#7e5d4e'
];

const HolidayCalendar = (props) => {
  const userRole = JSON.parse(localStorage.getItem('role'));
  const [myEvents, setMyEvents] = useState(props.holiday);
  const [tempEvent, setTempEvent] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [start, startRef] = useState(null);
  const [end, endRef] = useState(null);
  const [popupEventTitle, setTitle] = useState('');
  const [popupEventDescription, setDescription] = useState('');
  const [popupEventAllDay, setAllDay] = useState(true);
  const [popupEventDate, setDate] = useState([]);
  const [popupEventStatus, setStatus] = useState('busy');
  const [mySelectedDate, setSelectedDate] = useState(now);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [colorAnchor, setColorAnchor] = useState(null);
  const [selectedColor, setSelectedColor] = useState('');
  const [tempColor, setTempColor] = useState('');
  const colorPicker = useRef();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [id, setId] = useState('');
  useEffect(() => {
    props.getHolidays();
  }, [getHolidays]);
  console.log(props, 'propooooo');

  const view = useMemo(() => {
    console.log('jjj');
    return {
      calendar: { labels: true }
    };
  }, []);

  const colorButtons = useMemo(
    () => [
      'cancel',
      {
        text: 'Set',
        keyCode: 'enter',
        handler: () => {
          setSelectedColor(tempColor);
          setColorPickerOpen(false);
        },
        cssClass: 'mbsc-popup-button-primary'
      }
    ],
    [tempColor]
  );

  const loadPopupForm = useCallback((event) => {
    console.log(event, 'event============================');
    setTitle(event.title);
    setDescription(event.description);
    // setStartDate(e?vent.start);
    // setEndDate();?
    setDate([
      event.start,
      event._days.length > 0 ? event._days[event._days.length - 1] : event._days[0]
    ]);
    setAllDay(event.allDay || false);
    setStatus(event.status || 'busy');
    setSelectedColor(event.color || '');
    setId(event._id);
  }, []);

  const onClose = useCallback(() => {
    if (!isEdit) {
      // refresh the list, if add popup was canceled, to remove the temporary event
      setMyEvents([...myEvents]);
    }
    setOpen(false);
  }, [isEdit, myEvents]);

  const selectColor = useCallback((color) => {
    setTempColor(color);
  }, []);

  const openColorPicker = useCallback(
    (ev) => {
      selectColor(selectedColor || '');
      setColorAnchor(ev.currentTarget);
      setColorPickerOpen(true);
    },
    [selectColor, selectedColor]
  );

  const changeColor = useCallback(
    (ev) => {
      const color = ev.currentTarget.getAttribute('data-value');
      selectColor(color);
      if (!colorPicker.current.s.buttons.length) {
        setSelectedColor(color);
        setColorPickerOpen(false);
      }
    },
    [selectColor, setSelectedColor]
  );
  const saveEvent = useCallback(() => {
    console.log(popupEventDate, '-=====================ddddddddddd=============');
    const newEvent = {
      id: tempEvent.id,
      title: popupEventTitle,
      description: popupEventDescription,
      start: popupEventDate[0],
      end: popupEventDate[1],
      allDay: popupEventAllDay,
      status: popupEventStatus,
      // color: tempEvent.color
      color: selectedColor || tempEvent.color,
      user: userRole
    };

    console.log(newEvent, 'newEv ');

    if (isEdit) {
      // update the event in the list
      const index = myEvents.findIndex((x) => x.id === tempEvent.id);
      const newEventList = [...myEvents];

      console.log(newEvent, id, 'newList=====================================');
      props.updateHoliday({ newEvent, id });
      newEventList.splice(index, 1, newEvent);
      setMyEvents(newEventList);
      // here you can update the event in your storage as well
      // ...
    } else {
      // add the new event to the list
      props.createHoliday(newEvent);
      setMyEvents([...myEvents, newEvent]);
      // here you can add the event to your storage as well
      // ...
    }
    setSelectedDate(popupEventDate[0]);
    // close the popup
    setOpen(false);
  }, [
    isEdit,
    myEvents,
    popupEventAllDay,
    popupEventDate,
    popupEventDescription,
    popupEventStatus,
    popupEventTitle,
    tempEvent,
    selectedColor
  ]);

  const deleteEvent = useCallback(
    (event) => {
      props.deleteHoliday(event._id);
      setMyEvents(myEvents.filter((item) => item.id !== event.id));
      setTimeout(() => {
        snackbar({
          button: {
            action: () => {
              setMyEvents((prevEvents) => [...prevEvents, event]);
            },
            text: 'Undo'
          },
          message: 'Event deleted'
        });
      });
    },
    [myEvents]
  );
  const onDeleteClick = useCallback(() => {
    console.log(tempEvent, 'tempE  ');
    deleteEvent(tempEvent);
    setOpen(false);
  }, [deleteEvent, tempEvent]);
  const onEventDeleted = useCallback(
    (args) => {
      deleteEvent(args.event);
    },
    [deleteEvent]
  );

  const onEventUpdated = useCallback((args) => {
    // here you can update the event in your storage as well, after drag & drop or resize
    // ...
  }, []);
  // handle popup form changes

  const titleChange = useCallback((ev) => {
    setTitle(ev.target.value);
  }, []);

  const descriptionChange = useCallback((ev) => {
    setDescription(ev.target.value);
  }, []);

  const allDayChange = useCallback((ev) => {
    setAllDay(ev.target.checked);
  }, []);

  const dateChange = useCallback((args) => {
    setDate(args.value);
  }, []);
  const statusChange = useCallback((ev) => {
    setStatus(ev.target.value);
  }, []);

  // scheduler options

  const onSelectedDateChange = useCallback((event) => {
    setSelectedDate(event.date);
  });

  const onEventClick = useCallback(
    (args) => {
      console.log('event', args);
      setEdit(true);
      setTempEvent({ ...args.event });
      // fill popup form with event data
      loadPopupForm(args.event);
      setAnchor(args.domEvent.target);
      setOpen(true);
    },
    [loadPopupForm]
  );

  const onEventCreated = useCallback(
    (args) => {
      console.log('=======================', args);
      // createNewEvent(args.event, args.target)
      setEdit(false);
      setTempEvent(args.event);
      // fill popup form with event data
      loadPopupForm(args.event);
      setAnchor(args.target);
      // open the popup
      setOpen(true);
    },
    [loadPopupForm]
  );

  // datepicker options
  const controls = useMemo(() => (popupEventAllDay ? ['date'] : ['datetime']), [popupEventAllDay]);
  const respSetting = useMemo(
    () =>
      popupEventAllDay
        ? {
            medium: {
              controls: ['date'],
              touchUi: false
            }
          }
        : {
            medium: {
              controls: ['date'],
              touchUi: false,
              dataTimezone: 'utc',
              displayTimezone: 'local'
            }
          },
    [popupEventAllDay]
  );

  // popup options
  const headerText = useMemo(() => (isEdit ? 'Edit event' : 'New Event'), [isEdit]);
  const popupButtons = useMemo(
    () =>
      isEdit
        ? [
            'cancel',
            {
              handler: () => {
                saveEvent();
              },
              keyCode: 'enter',
              text: 'Save',
              cssClass: 'mbsc-popup-button-primary'
            }
          ]
        : [
            'cancel',
            {
              handler: () => {
                saveEvent();
              },
              keyCode: 'enter',
              text: 'Add',
              cssClass: 'mbsc-popup-button-primary'
            }
          ],
    [isEdit, saveEvent]
  );

  return (
    <>
      {userRole.role.title === 'HR' ||
      userRole.role.title === 'ADMIN' ||
      userRole.role.title === 'SUPER_ADMIN' ? (
        <>
          <Eventcalendar
            view={viewSettings}
            data={myEvents}
            clickToCreate="double"
            dragToCreate
            dragToMove
            dragToResize
            selectedDate={mySelectedDate}
            onSelectedDateChange={onSelectedDateChange}
            onEventClick={onEventClick}
            onEventCreated={onEventCreated}
            onEventDeleted={onEventDeleted}
            onEventUpdated={onEventUpdated}
            dataTimezone="utc"
            displayTimezone="local"
          />
          <Popup
            display="bottom"
            fullScreen
            contentPadding={false}
            headerText={headerText}
            anchor={anchor}
            buttons={popupButtons}
            isOpen={isOpen}
            onClose={onClose}
            responsive={responsivePopup}
          >
            <div className="mbsc-form-group">
              <Input label="Title" value={popupEventTitle} onChange={titleChange} />
              <Textarea
                label="Description"
                value={popupEventDescription}
                onChange={descriptionChange}
              />
            </div>
            <div className="mbsc-form-group">
              <Switch label="All-day" checked={popupEventAllDay} onChange={allDayChange} />
              <Input ref={startRef} label="Starts" />
              <Input ref={endRef} label="Ends" />
              <Datepicker
                select="range"
                controls={controls}
                touchUi={false}
                startInput={start}
                endInput={end}
                showRangeLabels={false}
                responsive={respSetting}
                onChange={dateChange}
                value={popupEventDate}
              />

              <div
                className="event-color-c"
                onClick={openColorPicker}
                onKeyDown={openColorPicker}
                aria-hidden="true"
              >
                <div className="event-color-label">Color</div>
                <div className="event-color" style={{ background: selectedColor }} />
              </div>
              <SegmentedGroup onChange={statusChange}>
                <SegmentedItem value="busy" checked={popupEventStatus === 'busy'}>
                  Show as busy
                </SegmentedItem>
                <SegmentedItem value="free" checked={popupEventStatus === 'free'}>
                  Show as free
                </SegmentedItem>
              </SegmentedGroup>
              {isEdit ? (
                <div className="mbsc-button-group">
                  <Button
                    className="mbsc-button-block"
                    color="danger"
                    variant="outline"
                    onClick={onDeleteClick}
                  >
                    Delete event
                  </Button>
                </div>
              ) : null}
            </div>
          </Popup>
          <Popup
            display="bottom"
            contentPadding={false}
            showArrow={false}
            showOverlay={false}
            anchor={colorAnchor}
            isOpen={colorPickerOpen}
            buttons={colorButtons}
            responsive={colorPopup}
            ref={colorPicker}
          >
            <div className="crud-color-row">
              {colors.map((color, index) => (
                <>
                  {index < 5 ? (
                    <div
                      key={index}
                      onClick={changeColor}
                      className={`'crud-color-c ' + (tempColor === color ? 'selected' : '')`}
                      data-value={color}
                      aria-hidden="true"
                    >
                      <div
                        className="crud-color mbsc-icon mbsc-font-icon mbsc-icon-material-check"
                        style={{ background: color }}
                      >
                        &nbsp;
                      </div>
                      &nbsp;
                    </div>
                  ) : (
                    <div
                      key={index}
                      onClick={changeColor}
                      className={`'crud-color-c ' + (tempColor === color ? 'selected' : '')`}
                      data-value={color}
                      aria-hidden="true"
                    >
                      <div
                        className="crud-color mbsc-icon mbsc-font-icon mbsc-icon-material-check"
                        style={{ background: color }}
                      >
                        &nbsp;
                      </div>
                      &nbsp;{' '}
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="crud-color-row">
              {colors.map((color, index) => {
                console.log('tets');
                return (
                  <>
                    {index >= 5 ? (
                      <div
                        key={index}
                        onClick={changeColor}
                        className={`'crud-color-c ' + (tempColor === color ? 'selected' : '')`}
                        data-value={color}
                        aria-hidden="true"
                      >
                        <div
                          className="crud-color mbsc-icon mbsc-font-icon mbsc-icon-material-check"
                          style={{ background: color }}
                        >
                          &nbsp;
                        </div>
                        &nbsp;
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
          </Popup>
        </>
      ) : (
        <>
          <p>Calender</p>
          <Eventcalendar
            theme="ios"
            themeVariant="light"
            clickToCreate={false}
            dragToCreate={false}
            dragToMove={false}
            dragToResize={false}
            data={props.holiday}
            view={view}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getHolidays: (item) => dispatch(getHolidays(item)),
  createHoliday: (item) => dispatch(createHoliday(item)),
  updateHoliday: (item) => dispatch(updateHoliday(item)),
  deleteHoliday: (deleteholiday) => dispatch(deleteHoliday(deleteholiday))
});
const mapStateToProps = (state) => ({
  holiday: state.holiday.holiday
});
export default connect(mapStateToProps, mapDispatchToProps)(HolidayCalendar);
