import { HolidayActionTypes } from './holiday.types';

const INITIAL_STATE = {
  holiday: [],
  holidaybyid: []
};

const holidayReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HolidayActionTypes.GET_HOLIDAYS:
      return {
        ...state,
        holiday: action.payload
      };
    case HolidayActionTypes.GET_HOLIDAY:
      return {
        holidaybyid: action.payload
      };
    case HolidayActionTypes.CREATE_HOLIDAY:
      return { holiday: [...state.holiday, action.payload] };
    case HolidayActionTypes.UPDATE_HOLIDAY:
      return {
        holiday: state.holiday.map((test) =>
          test._id === action.payload._id ? action.payload : test
        )
      };
    case HolidayActionTypes.DELETE_HOLIDAY:
      return {
        holiday: state.holiday.filter((emp) => emp._id !== action.payload)
      };
    default:
      return state;
  }
};

export default holidayReducer;
