import { LeaveActionTypes } from './leave.types';

const INITIAL_STATE = {
  leaves: [],
  userImage: null,
  allleaves: [],
  getLeves: []
};

const leaveReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LeaveActionTypes.GET_LEAVES:
      return {
        ...state,
        leaves: action.payload,
        allleaves: action.payload
      };
    case LeaveActionTypes.GET_LEAVE:
      return {
        ...state,
        leaves: action.payload,
        allleaves: action.payload
      };
    case LeaveActionTypes.GET_LEAVESBYADMIN:
      return {
        ...state,
        leaves: action.payload,
        allleaves: action.payload
      };
    case LeaveActionTypes.CREATE_LEAVE:
      return { leaves: [...state.leaves, action.payload] };

    case LeaveActionTypes.LEAVE_ACCEPT:
      return { leaves: [...state.leaves, action.payload] };
    case LeaveActionTypes.LEAVE_ACCEPT_BY_ADMIN:
      return { leaves: [...state.leaves, action.payload] };
    default:
      return state;
  }
};

export default leaveReducer;
