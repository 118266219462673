import axios from 'axios';
import { toast } from 'react-toastify';

import { LeaveActionTypes } from './leave.types';

const server = process.env.REACT_APP_BASE_URL;

const token = localStorage.getItem('token');

export const getLeaves = () => {
  console.log('');
  return (dispatch) => {
    if (token) {
      axios
        .get(`${server}leave/allleavesByGroup`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((data) => {
          dispatch({
            type: LeaveActionTypes.GET_LEAVES,
            payload: data.data
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
};

export const getLeave = (id) => {
  console.log('');
  return (dispatch) => {
    if (token) {
      axios
        .get(`${server}leave/accept/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((data) => {
          dispatch({
            type: LeaveActionTypes.GET_LEAVE,
            payload: data.data
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
};

export const getLeavesforAdmin = () => {
  console.log('');
  return (dispatch) => {
    if (token) {
      axios
        .get(`${server}leave/allleavesByGroupByadmin`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((data) => {
          dispatch({
            type: LeaveActionTypes.GET_LEAVESBYADMIN,
            payload: data.data
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
};

export const createLeave = (data) => {
  console.log('');
  return (dispatch) => {
    axios
      .post(`${server}leave/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((data) => {
        dispatch({
          type: LeaveActionTypes.CREATE_LEAVE,
          payload: data.data
        });
        if (data.status === 201) {
          toast.success(`Successfully send`);
          window.location.href = './employee';
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Invaild details`);
      });
  };
};
export const leaveAccept = (data) => {
  console.log('');
  return (dispatch) => {
    axios
      .post(`${server}approval/leave-approval`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((data) => {
        dispatch({
          type: LeaveActionTypes.LEAVE_ACCEPT,
          payload: data.data
        });
        if (data.status === 201) {
          toast.success(`${data.data.message}`);
          // window.location.href = './';
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Invaild details`);
      });
  };
};

export const leaveAcceptByAdmin = (data) => {
  console.log('');
  return (dispatch) => {
    axios
      .post(`${server}approval/leave-approval_byadmin`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((data) => {
        dispatch({
          type: LeaveActionTypes.LEAVE_ACCEPT_BY_ADMIN,
          payload: data.data
        });
        if (data.status === 201) {
          toast.success(`${data.data.message}`);
          // window.location.href = './';
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Invaild details`);
      });
  };
};
