import axios from 'axios';
import { toast } from 'react-toastify';

import { EmployeeActionTypes } from './employee.types';

const server = process.env.REACT_APP_BASE_URL;

const token = localStorage.getItem('token');

export const getEmployee = (id) => {
  console.log('');
  return (dispatch) => {
    axios

      .get(`${server}user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      .then((data) => {
        dispatch({
          type: EmployeeActionTypes.GET_EMPLOYEE,
          payload: data.data
        });
      })

      .catch((err) => {
        console.log(err);
      });
  };
};

export const getEmployees = () => {
  console.log('');
  return (dispatch) => {
    if (token) {
      axios

        .get(`${server}user/getAllEmployees`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

        .then((data) => {
          dispatch({
            type: EmployeeActionTypes.GET_EMPLOYEES,
            payload: data.data
          });
        })

        .catch((err) => {
          console.log(err);
        });
    }
  };
};

export const getEmployeesFilter = () => {
  console.log('');
  return (dispatch) => {
    if (token) {
      axios

        .get(`${server}user/getAllEmployees/?role="PM"`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

        .then((data) => {
          dispatch({
            type: EmployeeActionTypes.GET_EMPLOYEES,
            payload: data.data
          });
        })

        .catch((err) => {
          console.log(err);
        });
    }
  };
};

export const createEmployee = (data) => {
  console.log('');
  return (dispatch) => {
    axios
      .post(`${server}user/onboard`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((data) => {
        dispatch({
          type: EmployeeActionTypes.CREATE_EMPLOYEE,
          payload: data.data
        });
        if (data.status === 201 || data.status === 200) {
          console.log(data.data, 'user added');
          // toast.success(`Your password is ${data.data.credentials.password}`);
          window.location.href = './dashboard/user';
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Invaild details`);
      });
  };
};

export const deleteEmployee = (id) => {
  console.log('');
  return (dispatch) => {
    axios

      .delete(`${server}user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      .then((data) => {
        dispatch({
          type: EmployeeActionTypes.DELETE_EMPLOYEE,
          payload: data.data
        });

        if (data.status === 200) {
          toast.success('Successfully deleted');
          if (token) {
            axios

              .get(`${server}user/getAllEmployees`, {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              })

              .then((data) => {
                dispatch({
                  type: EmployeeActionTypes.GET_EMPLOYEES,
                  payload: data.data
                });
              })

              .catch((err) => {
                console.log(err);
              });
          }
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateEmployee = (data, id) => {
  console.log(data, 'userDetail');
  return (dispatch) => {
    axios

      .patch(`${server}user/${data.id}`, data.UserData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      .then((data) => {
        dispatch({
          type: EmployeeActionTypes.UPDATE_EMPLOYEE,
          payload: data.data
        });
        if (data.status === 201 || data.status === 200) {
          toast.success(`Successfully Updated`);
          window.location.href = './dashboard/user';
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
};

export const uploadImage = (data) => {
  console.log('');
  return (dispatch) => {
    axios

      .post(`${server}upload/uploadImage`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      .then((data) => {
        dispatch({
          type: EmployeeActionTypes.UPLOAD_IMAGE,
          payload: data.data
        });
      })

      .catch((err) => {
        console.log(err);
      });
  };
};
