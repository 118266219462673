import React, { useEffect } from 'react';
import { merge } from 'lodash';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { Grid, Stack, TextField, IconButton, Tooltip } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LoadingButton, MobileDateTimePicker } from '@mui/lab';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import ColorSinglePicker from './ColorSinglePicker';
import {
  getHolidays,
  createHoliday,
  updateHoliday,
  deleteHoliday
} from '../../../redux/holidays/holiday.actions';

const getInitialValues = (event, range) => {
  const _event = {
    title: '',
    description: '',
    textColor: '#1890FF',
    allDay: false,
    start: range ? new Date(range.start) : new Date(),
    end: range ? new Date(range.end) : new Date()
  };

  if (event || range) {
    return merge({}, _event, event[0]);
  }
  return _event;
};

CalendarForm.propTypes = {
  event: PropTypes.object,
  range: PropTypes.object,
  onCancel: PropTypes.func
};

function CalendarForm(props) {
  const navigate = useNavigate();
  const isCreating = !props.event;
  const COLOR_OPTIONS = [
    '#00AB55', // theme.palette.primary.main,
    '#1890FF', // theme.palette.info.main,
    '#94D82D', // theme.palette.success.main,
    '#FFC107', // theme.palette.warning.main,
    '#FF4842', // theme.palette.error.main
    '#04297A', // theme.palette.info.darker
    '#7A0C2E' // theme.palette.error.darker
  ];

  const UserSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    description: Yup.string().max(5000),
    end: Yup.date().when(
      'start',
      (start, schema) => start && schema.min(start, 'End date must be later than start date')
    ),
    start: Yup.date()
  });
  const userRole = JSON.parse(localStorage.getItem('role'));

  const formik = useFormik({
    initialValues: getInitialValues(props?.event, ''),
    // initialValues: {
    //   title: '',
    //   description: '',
    //   start: '',
    //   end: '',
    //   textColor: ''
    // },
    // validationSchema: UserSchema,
    onSubmit: (state) => {
      const newEvent = {
        title: state.title || props.event[0]?.title,
        description: state.description || props.event[0]?.description,
        start: props.event[0]?.start || props.start || state.start,
        end: props.event[0]?.end || props.end || state.end,
        textColor: props.event[0]?.textColor || state.textColor,
        user: userRole
      };

      if (props.id !== undefined) {
        if (props.id.length > 0 || props.id !== '') {
          props.updateHoliday({ newEvent, ...props.id });
        } else {
          props.createHoliday(newEvent);
        }
      }
      props.close();
      props.getHolidays();
    }
  });
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    isSubmitting,
    getFieldProps,
    setFieldValue
  } = formik;
  const handleDelete = async (id) => {
    try {
      props.deleteHoliday(id);
      props.close();
    } catch (error) {
      console.error(error);
    }
  };
  console.log(props, 'props-------------------------');
  const handleClose = () => {
    props.close();
  };
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" id="form" noValidate onSubmit={handleSubmit}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Title"
                          {...getFieldProps('title')}
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Description"
                          {...getFieldProps('description')}
                          error={Boolean(touched.description && errors.description)}
                          helperText={touched.description && errors.description}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDateTimePicker
                            label="Start date"
                            value={props.start || props.event[0]?.start || values.start}
                            onChange={(date) => setFieldValue('start', date)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDateTimePicker
                            label="End date"
                            value={props.event[0]?.end || props.end || values.end}
                            onChange={(date) => setFieldValue('end', date)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <ColorSinglePicker {...getFieldProps('textColor')} colors={COLOR_OPTIONS} />
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
              </Grid>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid xs={6}>
                  {!isCreating && (
                    <Tooltip title="Delete Event">
                      <IconButton onClick={() => handleDelete(props.id)}>
                        <Icon icon={trash2Fill} width={20} height={20} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid xs={6} className="margin-left">
                  <LoadingButton size="large" type="submit" variant="contained">
                    Submit
                  </LoadingButton>
                  <LoadingButton
                    size="large"
                    type="button"
                    variant="outlined"
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  getHolidays: (item) => dispatch(getHolidays(item)),
  createHoliday: (item) => dispatch(createHoliday(item)),
  updateHoliday: (item) => dispatch(updateHoliday(item)),
  deleteHoliday: (deleteholiday) => dispatch(deleteHoliday(deleteholiday))
});
const mapStateToProps = (state) => ({
  holidays: state.holiday.holiday
});
export default connect(mapStateToProps, mapDispatchToProps)(CalendarForm);
