import React, { useState, useRef, useEffect } from 'react';
import { Card, Button, Container, DialogTitle, useMediaQuery } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { useTheme } from '@mui/material/styles';
// import { DialogAnimate } from '../components/animate';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import Page from '../components/Page';
import { CalendarForm, CalendarStyle, CalendarToolbar } from '../components/_dashboard/calendar';
import { getHolidays, getHoliday } from '../redux/holidays/holiday.actions';

function Calendar(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState('dayGridMonth');
  const calendarRef = useRef(null);
  const calendarEl = calendarRef.current;
  const [open, setOpen] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = useState(false);
  const [myEvents, setMyEvents] = useState(props.holidays);
  const [selectId, setSelectedId] = useState('');
  const [eventById, setEventById] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    props.getHolidays();
  }, [getHolidays]);
  useEffect(() => {
    setMyEvents(props.holidays);
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = isMobile ? 'listWeek' : 'dayGridMonth';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [isMobile, setMyEvents]);

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };
  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };
  const [selectDate, setSelectDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const handleSelectRange = (arg) => {
    setSelectDate(arg.start);
    setEndDate(arg.end);
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }
    setOpen(true);
    // dispatch(selectRange(arg.start, arg.end));
  };
  const handleSelectEvent = (arg) => {
    console.log('edit value');
    setSelectedId(arg.event.id);

    props.getHoliday(arg.event.id);
    setEventById(props.holiday);
    setOpen(true);
    setEventById([]);
    setSelectedEvent(true);
  };
  const handleCloseModal = () => {
    setSelectedId('');
    setSelectedEvent(false);
    setEventById([]);
    setOpen(false);
    props.getHolidays();
  };

  return (
    <>
      <Page title="Calendar | Minimal-UI">
        <Container maxWidth="xl">
          {/* <HeaderBreadcrumbs
          heading="Calendar"
        //   links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Calendar' }]}
          moreLink="https://fullcalendar.io/docs/react"
          action={
          }
        /> */}
          <Button
            variant="contained"
            // startIcon={<Icon icon={plusFill} width={20} height={20} />}
            // onClick={handleAddEvent}
          >
            New Event
          </Button>

          <Card>
            <CalendarStyle>
              <CalendarToolbar
                date={date}
                view={view}
                onNextDate={handleClickDateNext}
                onPrevDate={handleClickDatePrev}
                onToday={handleClickToday}
                onChangeView={handleChangeView}
              />
              <FullCalendar
                weekends
                editable
                droppable
                selectable
                events={props.holidays}
                ref={calendarRef}
                rerenderDelay={10}
                initialDate={date}
                initialView={view}
                dayMaxEventRows={3}
                eventDisplay="block"
                headerToolbar={false}
                allDayMaintainDuration
                eventResizableFromStart
                select={handleSelectRange}
                //   eventDrop={handleDropEvent}
                eventClick={handleSelectEvent}
                //   eventResize={handleResizeEvent}
                //   height={isMobile ? 'auto' : 720}
                plugins={[
                  listPlugin,
                  dayGridPlugin,
                  timelinePlugin,
                  timeGridPlugin,
                  interactionPlugin
                ]}
              />
            </CalendarStyle>
          </Card>
          {open ? (
            <Dialog
              open={open}
              // TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{selectedEvent ? 'Edit Event' : 'Add Event'}</DialogTitle>
              <CalendarForm
                close={handleCloseModal}
                id={selectId}
                start={selectDate}
                end={endDate}
                event={props.holiday}
              />
            </Dialog>
          ) : null}
        </Container>
      </Page>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getHoliday: (item) => dispatch(getHoliday(item)),
  getHolidays: (item) => dispatch(getHolidays(item))
  // createHoliday: (item) => dispatch(createHoliday(item))
  // updateHoliday: (item) => dispatch(updateHoliday(item)),
  // deleteHoliday: (deleteholiday) => dispatch(deleteHoliday(deleteholiday))
});
const mapStateToProps = (state) => ({
  holidays: state.holiday.holiday,
  holiday: state.holiday.holidaybyid
});
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
