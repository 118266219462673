import { RoleActionTypes } from './role.types';

const INITIAL_STATE = {
  roles: [],
  currentRole: null
};

const roleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RoleActionTypes.GET_ROLES:
      return {
        ...state,
        roles: action.payload
      };

    case RoleActionTypes.GET_ROLE:
      return { roles: action.payload };
    case RoleActionTypes.UPDATE_ROLE:
      return {
        roles: state.roles.map((test) => (test._id === action.payload._id ? action.payload : test))
      };
    case RoleActionTypes.CREATE_ROLE:
      return { roles: [...state.roles, action.payload] };
    case RoleActionTypes.DELETE_EMPLOYEE:
      return {
        roles: state.roles.filter((emp) => emp._id !== action.payload)
      };

    default:
      return state;
  }
};

export default roleReducer;
