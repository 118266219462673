import * as Yup from 'yup';

import React, { useState, useEffect, useRef } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
// material
import {
  Card,
  CardContent,
  Box,
  Grid,
  Stack,
  Button,
  Container,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  TextField,
  NativeSelect
} from '@mui/material';
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
// components
import { connect } from 'react-redux';
import Page from '../components/Page';

import { BreadCrumbs } from '../components/_dashboard/user';
//

import { createRole } from '../redux/role/role.actions';

function CreateRole(props) {
  const ref = useRef(null);

  const [reportingManager, setReportingManager] = useState([]);

  const userRole = JSON.parse(localStorage.getItem('role'));
  const breadcrumbs = [
    { title: 'Dashboard', path: '/dashboard/app' },
    { title: 'Role', path: '/dashboard/role' },
    { title: 'New role', path: '/dashboard/rolecreate' }
  ];
  const token = localStorage.getItem('token');

  const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
  }));

  const navigate = useNavigate();

  const UserSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name required')
  });

  const formik = useFormik({
    initialValues: {
      title: ''
    },
    // validationSchema: UserSchema,
    onSubmit: (state) => {
      const RoleData = {
        title: state.title,
        user: userRole
      };

      props.createRole(RoleData);
    }
  });

  const { errors, touched, handleSubmit, handleChange, isSubmitting, getFieldProps } = formik;

  return (
    <Page title="Role | Techstriker-Portal">
      <Container>
        <h2>Create a new role</h2>
        <BreadCrumbs breadcrumbs={breadcrumbs} />

        <Box sx={{ flexGrow: 1 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Stack spacing={3}>
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  label="Title"
                                  {...getFieldProps('title')}
                                  error={Boolean(touched.title && errors.title)}
                                  helperText={touched.title && errors.title}
                                />
                              </Grid>
                            </Grid>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid xs={12} className="margin-left">
                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                    <LoadingButton size="large" type="button" variant="outlined">
                      Cancel
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Stack>
            </Form>
          </FormikProvider>
        </Box>
      </Container>
    </Page>
  );
}

const mapStateToProps = (state) => ({
  user: state,
  userImage: state.employee.userImage,
  role: state.role.roles,
  employees: state.employee.employees
});

const mapDispatchToProps = (dispatch) => ({
  createRole: (user) => dispatch(createRole(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRole);
