// routes
// import Router from './routes';
import React, { useState } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
// import RouterComp from './Router';
// theme
// ----------------------------------------------------------------------
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/Login';
import Auth from './Auth';
import DashboardApp from './pages/DashboardApp';
import DashboardNavbar from './layouts/dashboard/DashboardNavbar';
import DashboardSidebar from './layouts/dashboard/DashboardSidebar';
import User from './pages/User';
import CreateUser from './pages/CreateUser';
import EditUser from './pages/EditUser';
import UserView from './pages/UserView';
import Role from './pages/Role';
import CreateRole from './pages/CreateRole';
import HolidayCalendar from './pages/holidayCalendar';
import LeaveApproval from './pages/Leave/approval';
import Leave from './pages/Leave';
import LeaveForm from './pages/Leave/create';
import LeaveListing from './pages/Leave/leaveList';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import Page404 from './pages/Page404';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import './App.css';
import './custom.css';
import Calendar from './pages/Calendar';
import SuperLogin from './pages/SuperAdmin/Login';

export default function App() {
  const APP_BAR_MOBILE = 64;
  const APP_BAR_DESKTOP = 92;
  const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
  });

  const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP + 24,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }));

  function PrivateOutlet() {
    const [open, setOpen] = useState(false);
    return Auth.checkAuth() ? (
      <RootStyle>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    ) : (
      <Navigate to="/login" />
    );
  }
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      {/* <Router> */}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<PrivateOutlet />}>
          <Route path="app" element={<DashboardApp />} />
          <Route path="user" element={<User />} />
          <Route path="usercreate" element={<CreateUser />} />
          <Route path="useredit/:id" element={<EditUser />} />
          <Route path="userview/:id" element={<UserView />} />
          <Route path="role" element={<Role />} />
          <Route path="rolecreate" element={<CreateRole />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="leave/list" element={<Leave />} />
          <Route path="leave/approval" element={<LeaveApproval />} />
          <Route path="leave/create" element={<LeaveForm />} />
          <Route path="leave/:id" element={<LeaveListing />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/superadmin/login" element={<SuperLogin />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      {/* </Router> */}
      <ToastContainer />
    </ThemeConfig>
  );
}
