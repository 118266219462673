import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------
import { connect } from 'react-redux';
import { getEmployees, deleteEmployee } from '../../../redux/employee/employee.actions';

import { AlertDialog } from './index';

function UserMoreMenu(props) {
  console.log(props.id, 'iiiiiiiiiiiiiiiiiiiiiiiiiiiiii');

  const navigate = useNavigate();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deletedId, setDeletedId] = useState('');
  const handleDelete = (id) => {
    setModalIsOpen(true);
    setIsOpen(false);
    setDeletedId(id);
  };
  const handleDeleted = () => {
    props.deleteEmployee(deletedId);
    setModalIsOpen(false);
  };

  const handleEdit = (id) => {
    console.log(id, 'iiiiiiiiiiiiiiiiiiiidddddddddddddddddddd-----------------');
    navigate(`/dashboard/useredit/${id}`, { replace: true });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleDelete(props.id)}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* <MenuItem onClick={() => handleEdit(props.id)} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>

      {modalIsOpen ? (
        <AlertDialog
          open={() => setModalIsOpen(true)}
          handleDelete={() => handleDeleted()}
          handleClose={() => setModalIsOpen(false)}
          title="employee"
        />
      ) : null}
    </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  getEmployees: (user) => dispatch(getEmployees(user)),
  deleteEmployee: (deleteuser) => dispatch(deleteEmployee(deleteuser))
});
const mapStateToProps = (state) => ({
  user: state.employee.employees
});
export default connect(mapStateToProps, mapDispatchToProps)(UserMoreMenu);
