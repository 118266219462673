import request from 'request';
import { toast } from 'react-toastify';
import axios from 'axios';

import { UserActionTypes } from './user.types';

const server = `${process.env.REACT_APP_BASE_URL}admin/login`;
const userLoginRoute = `${process.env.REACT_APP_BASE_URL}user/login`;

// export const setCurrentUser = (data) => {
//   return (dispatch) => {
//     request.post(
//       {
//         url: server,
//         form: {
//           username: data.username,
//           password: data.password
//         }
//       },

//       (err, response, body) => {
//         if (response.statusMessage === 'Not Found') {
//           alert('invaild password');
//         } else {
//           const parsedBody = JSON.parse(body);
//           const payloadData = {
//             userData: parsedBody.user,
//             token: parsedBody.token
//           };
//           dispatch({
//             type: UserActionTypes.SET_CURRENT_USER,
//             payload: payloadData
//           });
//           localStorage.setItem('token', parsedBody.token);
//           localStorage.setItem('role', JSON.stringify(parsedBody.userDetail));
//           console.log('roles', JSON.stringify(parsedBody.userDetail));
//         }
//       }
//     );
//   };
// };

export const setCurrentUser = (data) => {
  console.log(data);
  return (dispatch) => {
    axios
      .post(`${server}`, {
        username: data.username,
        password: data.password
      })
      .then((body) => {
        dispatch({
          type: UserActionTypes.SET_CURRENT_USER,
          payload: body.data
        });

        localStorage.setItem('token', body.data.token);
        localStorage.setItem('role', JSON.stringify(body.data.userDetail));
        localStorage.setItem('referId', body.data.userDetail.referId);
        // localStorage.setItem('reportingManagerId', body.data.userDetail.reportingManager);
        const token = localStorage.getItem('token');
        if (token) {
          window.location.href = './dashboard/app';
          // history.push('/');
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const userLogin = (data) => {
  console.log(data);
  return (dispatch) => {
    axios
      .post(`${userLoginRoute}`, {
        email: data.email,
        password: data.password,
        isAdmin: 'false'
      })
      .then((body) => {
        dispatch({
          type: UserActionTypes.USER_LOGIN,
          payload: body.data
        });

        localStorage.setItem('token', body.data.token);
        localStorage.setItem('role', JSON.stringify(body.data.userDetail));
        localStorage.setItem('referId', body.data.userDetail.referId);
        localStorage.setItem('reportingManagerId', body.data.userDetail.reportingManager);
        const token = localStorage.getItem('token');
        if (token) {
          window.location.href = './dashboard';
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateUserData = (user) => ({
  type: UserActionTypes.SET_USER_DATA,
  payload: user
});
