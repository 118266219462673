import axios from 'axios';
import { toast } from 'react-toastify';

import { RoleActionTypes } from './role.types';

const server = process.env.REACT_APP_BASE_URL;

const token = localStorage.getItem('token');

export const getRole = (id) => {
  console.log('');
  return (dispatch) => {
    axios

      .get(`${server}role/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      .then((data) => {
        dispatch({
          type: RoleActionTypes.ROLE,
          payload: data.data
        });
      })

      .catch((err) => {
        console.log(err);
      });
  };
};

export const getRoles = () => {
  console.log('getttt');
  return (dispatch) => {
    if (token) {
      axios
        .get(`${server}role/getAllRoles`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((data) => {
          console.log(data, 'daat');
          dispatch({
            type: RoleActionTypes.GET_ROLES,
            payload: data.data
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
};

export const createRole = (data) => {
  console.log('');
  return (dispatch) => {
    axios
      .post(`${server}role/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((data) => {
        dispatch({
          type: RoleActionTypes.CREATE_ROLE,
          payload: data.data
        });
        if (data.status === 201 || data.status === 200) {
          toast.success(`Successfully addeed`);
          window.location.href = './dashboard/role';
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteRole = (id) => {
  console.log('');
  return (dispatch) => {
    axios

      .delete(`${server}role/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      .then((data) => {
        dispatch({
          type: RoleActionTypes.DELETE_ROLE,
          payload: data.data
        });

        if (data.status === 200 || data.status === 201) {
          toast.success('Successfully deleted');
          if (token) {
            axios

              .get(`${server}role/getAllRoles`, {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              })

              .then((data) => {
                dispatch({
                  type: RoleActionTypes.GET_ROLES,
                  payload: data.data
                });
              })

              .catch((err) => {
                console.log(err);
              });
          }
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
};
