import { combineReducers } from 'redux';
import employeeReducer from './employee/employee.reducer';
import userReducer from './user/user.reducer';
import roleReducer from './role/role.reducer';
import holidayReducer from './holidays/holiday.reducer';
import leaveReducer from './leave/leave.reducer';

export default combineReducers({
  user: userReducer,
  employee: employeeReducer,
  role: roleReducer,
  holiday: holidayReducer,
  leave: leaveReducer
});
